/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  font-family: 'Poppins', sans-serif;
  color: #4a4a4a; /* Darker gray for text */
  background-color: #f0f0f0; /* Light gray for background */
}

a {
  text-decoration: none;
  color: inherit;
}

.App {
  text-align: center;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5%;
  background-color: #222; /* Darker background */
  color: #ffffff; /* White text */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.header:hover {
  background-color: rgba(34, 34, 34, 0.9);
}

.logo-text h1 {
  font-size: 1.8rem;
  margin: 0;
  color: #f39c12; /* Warm gold for logo text */
}

.logo-text p {
  font-size: 0.9rem;
  color: #b0b0b0; /* Light gray for subtitle */
}

/* Navigation Styles */
.nav-list li a {
  font-weight: bold;
  color: #f39c12; /* Warm gold for primary text color */
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Enhanced Hover Effects */
.nav-list li a:hover {
  color: #ffffff; /* White text on hover */
  background-color: #e67e22; /* Slightly darker shade of primary color */
}

/* Hero Section */
.hero-overlay {
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
}

.hero-overlay h2 {
  color: #f39c12; /* Warm gold for hero text */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
}

/* Company Overview Section */
.company-overview h2 {
  color: #2c3e50; /* Dark navy for headings */
}

.company-overview p {
  color: #34495e; /* Darker gray for paragraph text */
}

/* Commodities Section */
.commodities-title {
  color: #2c3e50; /* Dark navy for title */
}

.commodity-item h3 {
  color: #d35400; /* Rich orange for commodity titles */
}

.commodity-item p {
  color: #555; /* Medium gray for descriptions */
}

/* Button Styles */
.btn-details {
  background-color: #ffffff; /* White background for button */
  color: #d35400; /* Rich orange for button text */
  border: 2px solid #d35400; /* Border matching text color */
}

/* Footer Styles */
.footer {
  text-align: center;
  background: #2c3e50; /* Dark navy for footer */
  color: #ffffff; /* White text */
}
