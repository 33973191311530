/* Sustainability Section */
.ContactUsSection {
  padding: 0; /* Remove padding to touch edges */
  background-color: #f0f4f8; /* Light background for a soft look */
  width: 100vw; /* Full viewport width */
  position: relative; /* Position relative for any absolute children */
  overflow: hidden; /* Prevent overflow */
}

.ContactUsContentContainer {
  max-width: 1200px; /* Increased maximum width for larger screens */
  margin: 0 auto; /* Center the container */
  padding: 40px 20px; /* Increased padding for a spacious look */
  background: linear-gradient(145deg, #ddcdcd, #f1f1f1); /* Soft gradient background */
  border-radius: 10px; /* Rounded corners for the container */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.ContactUsTitle {
  font-size: 2.8rem; /* Large title font */
  margin-bottom: 20px; /* Reduced spacing below the title */
  color: #333; /* Dark text for contrast */
  border-bottom: 5px solid #ff7043; /* Underline with accent color */
  display: inline-block; /* Makes the border only under the title */
  padding-bottom: 10px; /* Spacing below the title text */
  text-align: left; /* Align text to the left */
  font-weight: bold; /* Bold title for emphasis */
}

.ContactUsIntro {
  font-size: 1.4rem; /* Slightly larger intro text size */
  color: #555; /* Lighter text color */
  margin-bottom: 30px; /* Spacing below intro text */
  text-align: left; /* Align text to the left */
  line-height: 1.6; /* Improved line height for readability */
  font-style: italic; /* Italics for a different emphasis */
}

.ContactUsWriteUp {
  font-size: 1.1rem; /* Standard paragraph text size */
  color: #444; /* Darker gray for text */
  margin: 0 auto; /* Centering */
  max-width: 900px; /* Wider max width for larger screens */
  padding: 30px; /* Increased inner padding for spacious feel */
  background-color: #f7f9fc; /* Light blue-gray background */
  border-radius: 12px; /* More rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Softer shadow for depth */
  line-height: 1.7; /* Improved line height for clarity */
  text-align: justify; /* Justify text for better alignment */
}

.ContactUsWriteUp p {
  margin-bottom: 20px; /* Spacing between paragraphs */
  transition: color 0.3s ease; /* Smooth color transition */
}

.ContactUsWriteUp strong {
  color: #ff7043; /* Accent color for strong text */
  font-weight: bold; /* Bold for emphasis */
}

.ContactUsWriteUp p:hover {
  color: #d35400; /* Change text color on hover */
}

/* Hero Section */
.hero-section {
  position: relative;
  width: 100%; /* Full width */
  height: 60vh; /* Responsive height */
  background-color: #f7f7f7; /* Light background */
  margin: 0; /* No margin */
  overflow: hidden; 
}

.hero-image-container {
  width: 100%; /* Full width */
  height: 100%;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.8); /* Dim image slightly */
  transition: filter 0.5s ease;
}

.hero-image:hover {
  filter: brightness(0.6); /* Darker on hover */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Slightly more opaque */
}

.hero-overlay h2 {
  font-size: 3.5rem; /* Larger overlay text */
  color: #ffcc29; /* Bright accent color */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8); /* Stronger shadow */
  animation: fadeIn 1.5s ease-in-out; /* Fade-in animation */
  text-align: center; /* Center align */
  padding: 0 20px; /* Padding for mobile */
  max-width: 90%; /* Max width for overlay */
}

/* Fade-in Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* General Section Styles */
.additional-sections {
  background: #f4f4f4;
  padding: 20px;
  display: flex; /* Use flexbox for horizontal layout */
  flex-wrap: wrap; /* Allow wrapping of items */
  gap: 20px; /* Space between items */
}

/* What We Do Styles */
.what-we-do {
  flex-basis: 100%; /* Take full width for "What We Do" */
  display: flex; /* Use flexbox for inner alignment */
  justify-content: space-between; /* Space between content */
  align-items: flex-start; /* Align items at the start */
  margin-bottom: 20px; /* Space below this section */
}

.what-we-do .content {
  flex: 1; /* Allow content to grow */
}

.what-we-do .map-image {
  max-width: 200px; /* Set max width for logo */
  height: auto; /* Maintain aspect ratio */
}

/* Component Styles */
.quick-links, .commodities-services, .support, .social-media {
  background: #ecf0f1; /* Light grey background for individual components */
  border-radius: 8px;
  padding: 15px;
  flex: 1 1 200px; /* Allow flex items to grow and shrink, with a base width of 200px */
  min-width: 250px; /* Ensure a minimum width for better readability */
}

/* Heading Styles */
h2, h3 {
  color: #2c3e50;
}

/* Paragraph Styles */
p {
  color: #34495e;
}

/* List Styles */
ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
}

/* Social Media Icons */
.social-media {
  text-align: center;
  margin: 20px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Reduced spacing between icons */
}

.social-icon {
  width: 30px; /* Adjusted size for smaller icons */
  height: auto;
  transition: transform 0.3s;
}

.social-icon:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .ContactUsTitle {
    font-size: 2rem; /* Adjust title size for smaller screens */
  }

  .ContactUsIntro {
    font-size: 1.2rem; /* Adjust intro size for smaller screens */
  }

  .ContactUsWriteUp {
    font-size: 1rem; /* Adjust paragraph size for smaller screens */
    padding: 15px; /* Reduce padding for smaller screens */
  }
}
